<template>
  <v-container grid-list-xl fluid>
    <header-title title="Guía de compulsa"></header-title>
    <user-guide tactivity="compulsa"></user-guide>
  </v-container>
</template>

<script>
  import HeaderTitle from "@/components/HeaderTitle";
  import UserGuide from "@/components/UserGuide";
  export default {
    name: "certified-guide",
    components: {
      HeaderTitle,
      UserGuide,
    },
  };
</script>
